<template>
  <v-container id="myocarditis" fluid tag="section">
   <v-card class="px-5 py-3" style="margin-top:10px">
      <!-- <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <div style="float:left; margin-right:50px">副反応疑い</div>
            </div>
      </template>-->
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>検索条件</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="no_from" label="No(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="no_to" label="No(to)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_from" label="Age(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_to" label="Age(to)" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select :items="['男性','女性']" clearable v-model="gender" label="性別"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vaccinated_date_from"
                        label="接種日(from)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vaccinated_date_from"
                      @input="menu = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vaccinated_date_to"
                        label="接種日(to)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vaccinated_date_to"
                      @input="menu2 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="disease" label="症状名"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="['1回目','2回目','3回目','4回目','5回目']"
                    clearable
                    v-model="count"
                    label="接種回数"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="['男','女','不明']"
                    clearable
                    v-model="gender"
                    label="性別"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                <v-select
                  :items="['α','β','γ']"
                  clearable
                  v-model="evaluation"
                  label="評価"
                ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="lot_no_list"
                    clearable
                    v-model="lot_no"
                    label="ロットNo"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="['コミナティ筋注','コミナティRTU筋注（２価：起源株/オミクロン株 BA.1）','コミナティRTU筋注（２価：起源株/オミクロン株 BA.4-5）','コミナティ筋注５～11歳用','スパイクバックス筋注','スパイクバックス筋注（２価：起源株/オミクロン株BA.1）','ヌバキソビッド筋注']"
                    clearable
                    v-model="vaccine_name"
                    label="ワクチン名"
                  ></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          style="margin-top:10px; font-size:12px; text-align:right"
          v-if="search_conditions_text !==''"
        >
          <span>{{search_conditions_text}}</span>
          <v-icon
            id="copy_icon"
            title="検索条件をコピー"
            style="margin-left:5px;margin-right:10px"
            @click="copyURLToClipboard()"
          >mdi-content-copy</v-icon>
          <span>{{" 検索結果 : " + filteredItems.length +"件" }}</span>
          <input id="dummy" style="display:none" />
        </div>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          mobile-breakpoint="0"
          style="white-space: pre;"
          :footer-props="footer_props"
          :loading="items.length === 0"
        >
          <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
          <template v-slot:item="i">
            <!-- Since v-slot:item overrides how each row is rendered, I rebuild the row starting from <tr>. This allows me to add a class to <tr> based on any condition I want (in this case, the calorie count) -->
            <tr>
              <td>{{i.item.no}}</td>
              <td>{{i.item.age}}</td>
              <td>{{i.item.gender}}</td>
              <td>{{i.item.date_vaccinated}}</td>
              <td>{{i.item.date_occurred}}</td>
              <td>{{i.item.maker}}</td>
              <td>{{i.item.vaccine_name}}</td>
              <td>{{i.item.lot_no}}</td>
              <td>{{i.item.count}}</td>
              <td>{{i.item.basic_disease}}</td>
              <td>{{i.item.PT}}</td>
              <td
                :class="{
                      'red lighten-5 red--text': i.item.result && i.item.result.includes('死亡')}"
              >{{i.item.result}}</td>
              <td>{{i.item.result_date}}</td>
              <td>{{i.item.PT_by_expert}}</td>
              <td>{{i.item.evaluation}}</td>
              <td>{{i.item.level}}</td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<style>
table {
  table-layout: fixed;
}
.v-data-table td {
  white-space: pre-line;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}

.v-data-table table{
  width : auto !important;
}

#myocarditis .v-data-table__wrapper table thead.v-data-table-header tr th{
  height : 80px;
}

</style>

<script>
import axios from 'axios'
import moment from 'moment'
import VACCINATED_DATE_FROM_INIT from '../../constants'
export default {
  name: 'Dashboard',

  // components: {
  //   apexchart: apexchart,
  // },

  data() {
    return {
      panel: 0,
      search_conditions_text: '',
      no_from: 0,
      no_to: 99999,
      age_from: '',
      age_to: '',
      gender: '',
      evaluation: '',
      vaccinated_date_from: VACCINATED_DATE_FROM_INIT,
      vaccinated_date_to: null,
      menu: false,
      menu2: false,
      disease: '',
      count: '',
      vaccine_name: '',
      lot_no: '',
      lot_no_list: [],
      footer_props: {
        'items-per-page-options': [10, 50, 100, 200],
        showFirstLastPage: true,
      },
      headers: [
        {
          sortable: false,
          text: 'no',
          value: 'no',
        },
        {
          text: '年齢',
          value: 'age',
        },
        {
          sortable: false,
          text: '性別',
          value: 'gender',
          width: '50px',
        },
        {
          text: '接種日',
          value: 'date_vaccinated',
          width: '100px',
        },
        {
          text: '発生日',
          value: 'date_occurred',
          width: '100px',
        },
        {
          sortable: false,
          text: '製薬会社',
          value: 'maker',
          width: '80px',
        },
        {
          sortable: false,
          text: 'ワクチン名',
          value: 'vaccine_name',
          width: '100px',
        },
        {
          text: 'ロットNo',
          value: 'lot_no',
          width: '80px',
        },
        {
          text: '接種回数',
          value: 'count',
          width: '80px',
        },
        {
          sortable: false,
          text: '基礎疾患等',
          value: 'basic_disease',
          width: '200px',
        },
        {
          sortable: false,
          text: '症状名',
          value: 'PT',
          width: '400px',
        },
        {
          sortable: false,
          text: '転帰内容',
          value: 'result',
          width: '80px',
        },
        {
          sortable: false,
          text: '転帰日',
          value: 'result_date',
          width: '100px',
        },
        {
          sortable: false,
          text: '専門家の評価PT',
          value: 'PT_by_expert',
          width: '140px',
        },
        {
          sortable: false,
          text: '専門家による\n因果関係評価',
          value: 'evaluation',
          width: '50px',
        },
        {
          sortable: false,
          text: '専門家の\nブライトン\n分類レベル',
          value: 'level',
          width: '60px',
        },
      ],
      items: [],
      toggle_filter: -1,
      commitAuthorArray: [],
      chartOptions: {
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
        },

        stroke: {
          width: 2,
          curve: 'straight',
          dashArray: 5,
        },
        markers: {
          size: 5,
        },
        xaxis: {
          type: 'category',
        },
        yaxis: {},
        legend: {
          position: 'top',
        },
      },
    }
  },

  computed: {
    filteredItems() {
      var conditions = []
      this.clearSearchConditionText()
      var text = ''
      if (this.no_to < 99999 || this.no_from > 0) {
        if (text !== '') text += ' / '
        text += 'No : '
        if (this.no_from > 0) text += this.no_from + '以上'
        if (this.no_to < 99999) text += this.no_to + '以下'
        conditions.push(this.filterByNo)
      }
      if (this.age_to !== '' || this.age_from !== '') {
        if (text !== '') text += ' / '
        text += '年齢 : '
        if (this.age_from !== '') text += this.age_from + '歳以上'
        if (this.age_to !== '') text += this.age_to + '歳以下'
        conditions.push(this.filterByAge)
      }
      if (this.gender) {
        if (text !== '') text += ' / '
        text += '性別 : '
        text += this.gender
        conditions.push(this.filterByGender)
      }
      if (
        this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT ||
        this.vaccinated_date_to !== null
      ) {
        if (text !== '') text += ' / '
        text += '接種日 : '
        if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
          text += this.vaccinated_date_from + ' から '
        }
        if (this.vaccinated_date_to !== null) {
          text += this.vaccinated_date_to + ' まで '
        }
        conditions.push(this.filterByVaccinatedDate)
      }
      if (this.disease) {
        if (text !== '') text += ' / '
        text += '症状名 : ' + this.disease + 'を含む'
        conditions.push(this.filterByDisease)
      }
      if (this.count) {
        if (text !== '') text += ' / '
        text += '接種回数 : ' + this.count
        conditions.push(this.filterByCount)
      }
      if (this.lot_no) {
        if (text !== '') text += ' / '
        text += 'ロットNo : ' + this.lot_no
        conditions.push(this.filterByLotNo)
      }
      if (this.evaluation) {
        if (text !== '') text += ' / '
        text += '評価 : '
        text += this.evaluation
        conditions.push(this.filterByEvaluation)
      }
      if (this.vaccine_name) {
        if (text !== '') text += ' / '
        text += 'ワクチン名 : ' + this.vaccine_name
        conditions.push(this.filterByVaccineName)
      }

      if (conditions.length > 0) {
        text = '検索条件 : [ ' + text + ' ]'
        this.setSearchConditionText(text)
        return this.items.filter(item => {
          return conditions.every(condition => {
            return condition(item)
          })
        })
      }
      return this.items
    },
  },

  mounted() {
    if (this.$route.query.no_from) this.no_from = this.$route.query.no_from
    if (this.$route.query.no_to) this.no_to = this.$route.query.no_to
    if (this.$route.query.age_from) this.age_from = this.$route.query.age_from
    if (this.$route.query.age_to) this.age_to = this.$route.query.age_to
    if (this.$route.query.gender) this.gender = this.$route.query.gender
    if (this.$route.query.vaccinated_date_from) {
      this.vaccinated_date_from = this.$route.query.vaccinated_date_from
    }
    if (this.$route.query.vaccinated_date_to) {
      this.vaccinated_date_to = this.$route.query.vaccinated_date_to
    }
    if (this.$route.query.disease) {
      this.disease = this.$route.query.disease
    }
    if (this.$route.query.count) this.count = this.$route.query.count
    if (this.$route.query.lot_no) {
      this.lot_no = this.$route.query.lot_no
    }
    if (this.$route.query.evaluation) {
      this.evaluation = this.$route.query.evaluation
    }
    if (this.$route.query.vaccine_name) this.vaccine_name = this.$route.query.vaccine_name

    this.getJson()
  },

  watch: {
    $route(to, from) {
      this.getJson()
    },
  },

  methods: {
    clearSearchConditionText() {
      this.search_conditions_text = ''
    },
    setSearchConditionText(text) {
      this.search_conditions_text = text
    },
    filterByNo(item) {
      return this.no_from <= item.no && this.no_to >= item.no
    },
    filterByAge(item) {
      if (item.age === '') return false
      var age = Number(item.age.replace('代', ''))
      if (this.age_from === '') return this.age_to >= age
      if (this.age_to === '') return this.age_from <= age

      return this.age_from <= age && this.age_to >= age
    },
    filterByVaccinatedDate(item) {
      var from = ''
      var to = ''
      if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
        from = moment(this.vaccinated_date_from).format('yyyy/MM/DD')
      }
      if (this.vaccinated_date_to) {
        to = moment(this.vaccinated_date_to).format('yyyy/MM/DD')
      }
      if (from === '') return to >= item.date_vaccinated
      if (to === '') return from <= item.date_vaccinated
      return from <= item.date_vaccinated && to >= item.date_vaccinated
    },
    filterByDisease(item) {
      return item.PT.includes(this.disease)
    },
    filterByCount(item) {
      return item.count === this.count
    },
    filterByLotNo(item) {
      return item.lot_no === this.lot_no
    },
    filterByVaccineName(item) {
      return item.vaccine_name && item.vaccine_name === this.vaccine_name
    },
    filterByGender(item) {
      return item.gender === this.gender
    },
    filterByEvaluation(item) {
      return item.evaluation.includes(this.evaluation)
    },

    getJson() {
      this.items = []
      var jsonFileName = 'myocarditis.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          // var noArray = []
          // for (const item of res.data) {
          //   if (!noArray.includes(item.no)) {
          //     this.items.push(item)
          //     noArray.push(item.no)
          //   }
          // }
          this.items = res.data
          for (const item of this.items) {
            if (!this.lot_no_list.includes(item.lot_no)) {
              this.lot_no_list.push(item.lot_no)
            }
          }
          this.lot_no_list.sort()
        }.bind(this),
      )
    },

    copyURLToClipboard() {
      var url = window.location.href.split('?')[0] + '?'

      var param = ''
      if (this.no_to < 99999 || this.no_from > 0) {
        if (this.no_from > 0) param += '&no_from=' + this.no_from
        if (this.no_to < 99999) param += '&no_to=' + this.no_to
      }
      if (this.age_to !== '' || this.age_from !== '') {
        if (this.age_from !== '') param += '&age_from=' + this.age_from
        if (this.age_to !== '') param += '&age_to=' + this.age_to
      }
      if (this.gender !== '') {
        param += '&gender=' + this.gender
      }
      if (
        this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT ||
        this.vaccinated_date_to !== null
      ) {
        if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
          param += '&vaccinated_date_from=' + this.vaccinated_date_from
        }
        if (this.vaccinated_date_to !== null) {
          param += '&vaccinated_date_to=' + this.vaccinated_date_to
        }
      }
      if (this.disease) {
        param += '&disease=' + this.disease
      }
      if (this.count) {
        param += '&count=' + this.count
      }
      if (this.lot_no) {
        param += '&lot_no=' + this.lot_no
      }
      if (this.evaluation) {
        param += '&evaluation=' + this.evaluation
      }
      if (this.vaccine_name) {
         param += '&vaccine_name=' + this.vaccine_name
      }

      // clipboardがhttpでは動作しないため、別のやり方で回避
      // navigator.clipboard.writeText(url + param)
      const newInput = document.createElement('input')
      newInput.type = 'text'
      newInput.value = url + param
      const dummy = document.getElementById('dummy')
      dummy.parentNode.insertBefore(newInput, dummy.nextSibling)
      newInput.select()
      document.execCommand('copy')
      newInput.parentNode.removeChild(newInput)
      document.getElementById('copy_icon').focus()
    },
  },
}
</script>
